import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/header'
import Feature from './components/feature'
import Cta from './components/cta'
import Avatars from './components/avatars'
import HeroSection from './components/herosection'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header/>
    <HeroSection/>
  </React.StrictMode>
);
