import React from 'react'

export default function header() {
  return (
    <>
         <div class="relative bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span class="sr-only">Uzmanımdan.com</span>
              <img class="h-8 w-auto sm:h-10" src="/logo.png" alt="uzmanimdan.com"></img>
            </a>
          </div>
         <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          </div>
        </div>
      </div>  
    </div>
    </>
  )
}
